import React, { useEffect } from 'react';
import { useCustomerCart } from '../../../hooks/useCart';
import { useCartScheduler } from '../../../hooks/useShippingScheduler';
import useAddresses from '../../../hooks/useAddresses';
import { useSetCustomerExistingShippingAddress, useSetShippingMethod, useSetCustomerBillingAddress } from '../../../hooks/useCart';
import { navigate } from 'gatsby';
import CartSummary from '../../Cart/cartSummary';
import Sections from '../Sections';
import styles from './style.module.css';

export default function LoggedIn() {
    return(
        <div className={styles.checkout}>
            <h1>Checkout</h1>
            <CheckoutContent />
        </div>
    );
}

function CheckoutContent() {
    const { loading: cartLoading, error: cartError, data: cartData } = useCustomerCart();
    const customerCart = cartData?.customerCart;

    const { loading: schedLoading, error: schedError, data: schedData } = 
        useCartScheduler(customerCart?.id, (customerCart ? false : true));

    const {billing, shipping} = useAddresses();
    const [setCustomerBillingAddress, { called: setBillingAddressCalled }] = useSetCustomerBillingAddress();
    const [setExistingShippingMutation, { called: setShippingAddressCalled }] = useSetCustomerExistingShippingAddress();
    const [setShippingMethodMutation] = useSetShippingMethod();

	useEffect(() => {
        if (customerCart) {
            // use this for init and re-init
            console.log('Customer cart has changed', customerCart);
        }
    }, [customerCart] )	// first render + when x changes

    if (cartLoading || schedLoading) {
        return <p>Loading ...</p>;
    }

    if (cartError) {
        return <p>Your basket is empty.</p>;
    }

    if (!customerCart?.items?.length) {
        return <p>Your basket is empty.</p>;
    }

    if (!setBillingAddressCalled && billing?.id) {
        setCustomerBillingAddress({variables: {cartId: customerCart.id, sameAsShipping: false, customerAddressId: billing.id}});
    }

    if (!setShippingAddressCalled 
        && (!customerCart.shipping_addresses.length || !customerCart.shipping_addresses[0].selected_shipping_method) 
        && (billing?.id || shipping?.length)) {
        const saId = (shipping.length && shipping[0].id) ? shipping[0].id : billing.id;
        setExistingShippingMutation({variables: {cartId: customerCart.id, customerAddressId: saId}})
        .then(() => {
            setShippingMethodMutation({ variables: { cartId: customerCart.id } })
            .then(navigate('/checkout')).catch(error => console.log(error))
        })
        .catch(error => console.log(error));
    }

    return(
        <div className={styles.content}>
            <Sections cartData={customerCart} />
            <CartSummary cartData={customerCart} mode="checkout" shippingSchedule={schedData?.shippingScheduleSetup} />
        </div>
    );
}
